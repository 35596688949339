import { createApp } from 'vue';
import config from './../config.json';
import components from './components/global';

// Get all containing elements that have vue components inside
let vue_prefix = config.plugin_prefix.replaceAll('_', '-');
const instanceContainers = document.querySelectorAll(`.${vue_prefix}-vue-instance`);

// Initialize a variable for holding a list of component tags for each Vue instance
let vueInstanceComponentTags = [];

//
// Recursive function that loops through all the child elements of the
// instance container and gathers the custom element tag names into an array
function getVueInstanceComponentTags (instance, node) {

    for (let i = 0; i < node.childNodes.length; i++) {
        let child = node.childNodes[i];
        getVueInstanceComponentTags(instance, child);

        if(child.tagName) {
            let tagName = child.tagName.toLowerCase();

            if(tagName.startsWith('tm-')) {
                vueInstanceComponentTags[instance].push(tagName);
            }
        }
    }
}


// Initialize a variable for containing all the vue instances
let vueInstances = [];

// Loop through each of
for (let i = 0; i < instanceContainers.length; i++) {

    // Add a key to the vueInstanceComponentTags variable to hold this instances list of tags
    vueInstanceComponentTags[i] = [];

    // Create the new Vue instance
    vueInstances[i] = createApp({});

    // get an array of vue instance component tags
    getVueInstanceComponentTags(i, instanceContainers[i]);

    // Loop through the custom element tags found inside the instance container
    for (let j = 0; j < vueInstanceComponentTags[i].length; j++) {

        // Register Vue components for each of the custom element tags
        for (const [name, component] of Object.entries(components)) {
            if (name === vueInstanceComponentTags[i][j]) {
                vueInstances[i].component(name, component);
            }
        }
    }

    // Mount it!
    vueInstances[i].mount(instanceContainers[i]);
}