<template>
    <div :class="classNames" >
        <slot></slot>
        <card v-for="card in cards" :card="card"></card>
    </div>
</template>

<script>
import Card from "./Card";
export default {
    components: {Card},
    props: {
        cards: {
            type: Array,
            default: [],
        },
        classNames: {
            type: String,
            default: "grid",
        }
    }
}
</script>
