<template>

  <div class="background-image-top" v-html="backgroundImageTop"></div>
  <header class="section-header ">
    <h2 data-animate class="section-title animated-heading"> {{ sectionTitle }} </h2>
    <div class="properties">
      <a v-for="(cityLabel, cityKey) in cities" @click.prevent="loadCityProperties(cityKey)"
         :class="{selected_city: selectedCity === cityKey}">{{ cityLabel }}</a>
    </div>
  </header>
  <div class="section-body stagger">
      <div v-if="loading" class="loader"></div>
      <Cards v-else :class-names="'property-card-container'" :cards='cards'></Cards>
  </div>
  <footer class="section-footer">
    <a class="button" :href="'/villas/' + getSelectedCityLink()">See All Our Properties</a>
  </footer>
  <div class="background-image-bottom" v-html="backgroundImageBottom"></div>

</template>

<script>

import axios from "axios";
import Cards from "./cards/Cards";

export default {
  name: "FeaturedVillas",
  components: {Cards},
  props: {

    backgroundImageTop: {
      type: String,
      default: ""
    },
    backgroundImageBottom: {
      type: String,
      default: ""
    },
    sectionTitle: {
      type: String,
      default: ""
    },
    cities: {
      type: Object,
      default: () => {}
    },
    limit: {
      type: Number,
      default: () => 3
    },
  },
  async mounted() {
    if (this.hasCities) {
      await this.loadCityProperties(this.cityKeys[0]);
      this.selectedCity = this.cityKeys[0];
    }
  },
  data() {
    return {
      cards: [],
      selectedCity: '',
      loading: false
    }
  },
    computed: {
      cityCount() {
          return Object.values(this.cities).length;
      },
      hasCities() {
          return this.cityCount > 0;
      },
      cityKeys() {
          return Object.keys(this.cities);
      }
    },
  methods: {
    async loadCityProperties(city) {
      if (city === this.selectedCity) {
        return;
      }

      this.selectedCity = city;

      this.filters = {
        location: city,
        limit: this.limit,
      }

      let url = window.luna_cabo_Data.restApis.featuredProperties;

      this.cards = [];

      this.loading = true;

      const response = await axios.post(url, {
        params: this.filters
      });

      this.loading = false;

      if (response.data.success) {
        if (response.data.cards.length > 0) {
          this.cards = response.data.cards;
        }
      }
    },
    getSelectedCityLink() {
      if(Object.values(this.cities).includes(this.selectedCity)) {
        return '?destination=' + this.slugify(this.selectedCity);
      }
      return '';
    },
    slugify(string) {
      let tmp = string.toLowerCase();

      tmp = tmp.replace(/[ñãôé~]/g, function(match) {
        switch(match) {
          case "~":
            return "a";
          case "ñ":
            return "n";
          case "ã":
            return "a";
          case "ô":
            return "o";
          case "é":
            return "e";
        }
      });

      tmp = tmp.replace(/[^a-z0-9- ]/g, "");
      tmp = tmp.replace(/ +/g, "-");
      return tmp.trim();
    },
  }

}
</script>