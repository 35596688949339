<template>
  <h3 v-if="cards.length !== 0" class="h5">
    Similar properties that match this property (bedrooms: {{ bedrooms }} and maximum guests {{ guests}}).
  </h3>
  <div v-if="loading" class="loader-similar-properties"></div>
  <Cards v-else :class-names="'property-card-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'" :cards="cards"></Cards>
  <h3 v-if="cards.length === 0" class="h5">
    No similar properties were found that match this property. It appears you have found a location that is truly "one of a kind."
  </h3>
</template>

<script>
import axios from "axios";
import Cards from "./cards/Cards";

export default {
  name: "SimilarProperties",
  props: {
    propertyId: {
      type: String,
      default: "0"
    },
    location: {
      type: String,
      default: ""
    },
    bedrooms: {
      type: String,
      default: "0"
    },
    guests: {
      type: String,
      default: "0"
    },
    limit: {
      type: Number,
      default: () => 3
    },
  },
  components: {
    Cards
  },
  data() {
    return {
      cards: [],
      filters: [],
      loading: false
    }
  },
  async mounted() {
    console.log('SimilarProperties mounted');

    let params = [];

    this.filters = {
      property_id: this.propertyId,
      location: this.location,
      bedrooms: this.bedrooms,
      guests: this.guests,
      limit: this.limit
    }

    let url = window.luna_cabo_Data.restApis.relatedProperties;

    if (params.length) {
      url += params.join("&")
    }

    this.loading = true;

    const response = await axios.post(url, {
      params: this.filters
    });

    this.loading = false;

    if (response.data.success) {
      if (response.data.cards.length > 0) {

        this.cards = response.data.cards;
        const link = document.getElementById('similar-properties-link');

        // I Frank, was wrong! LoL
        if (link) {
          link.style.display = 'inline-block';
        }

      } // end if ./response.data.cards.length > 0)
    }
  }
}
</script>