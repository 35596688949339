<template>
  <div class="luna-cabo-property-search property-search-wrapper">

    <div v-if="showLoader" class="loader">
      <div class="spinner"></div>
    </div>

    <div class="form-wrapper">

      <svg class="cresent-moon" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.0872 4.34565e-08C8.97498 0.227231 8.84285 0.459693 8.69216 0.680609C8.37465 1.14607 8.02323 1.59203 7.73118 2.07125C7.4539 2.52626 7.28721 3.0483 6.97602 3.47746C6.61336 3.97761 6.62638 4.52607 6.56829 5.07461C6.46407 6.05865 6.38388 7.04534 6.25891 8.02689C6.18758 8.58711 6.1879 9.11535 6.47574 9.63146C6.62562 9.9002 6.70023 10.2069 6.81366 10.4948C6.8304 10.5373 6.86665 10.5801 6.90595 10.606C7.59343 11.0605 7.8606 11.8288 8.38293 12.4086C9.05167 13.1509 9.73305 13.8905 10.6311 14.3666C11.1095 14.6202 11.6959 14.6939 12.2392 14.8281C12.7268 14.9486 13.2175 15.0956 13.7149 15.1329C14.5191 15.1933 15.3305 15.1972 16.1377 15.1786C16.6217 15.1674 17.1018 15.0445 17.5865 15.0146C18.0988 14.983 18.5427 14.7884 18.992 14.5858C19.1305 14.5234 19.2869 14.4974 19.5 14.4361C19.4265 14.889 19.4828 15.2649 19.1421 15.6205C17.9548 16.8598 16.4734 17.6285 14.8374 18.1387C14.1183 18.3629 13.3538 18.4569 12.6073 18.597C11.9954 18.7118 11.3796 18.8077 10.7653 18.911C10.6481 18.9307 10.53 18.9456 10.401 18.9839C10.0648 19.005 9.73985 19.005 9.38078 18.9862C9.14536 18.8136 8.96764 18.6047 8.73877 18.5155C7.76942 18.1377 6.78474 17.7958 5.8059 17.4402C4.6224 17.0102 3.58118 16.3617 2.63214 15.5676C1.6952 14.7837 1.30689 13.7228 0.98645 12.627C0.817922 12.0508 0.661751 11.4712 0.5 10.8931C0.5 10.4283 0.5 9.9636 0.522749 9.45981C0.538042 8.88108 0.669618 8.36923 0.866891 7.86662C1.10594 7.25758 1.29309 6.62767 1.56791 6.03434C1.87298 5.37571 2.25213 4.74842 2.59778 4.10688C2.67877 3.95658 2.72356 3.77626 2.83881 3.65729C3.20538 3.27889 3.57781 2.90157 3.98373 2.56293C4.72522 1.94436 5.51424 1.39101 6.4063 0.970437C7.00668 0.687376 7.56669 0.326219 8.14476 4.34565e-08C8.44946 -1.44855e-08 8.75417 -1.44855e-08 9.0872 4.34565e-08Z" fill="#C7E8EB"/>
      </svg>

      <form>

        <div class="select-wrapper destination">
          <svg class="svg svg-destination" viewBox="0 0 14 16">
            <use xlink:href="#destination"></use>
          </svg>
          <select v-model="filters.destination">
            <option :selected="filters.destination === '-1'" value="-1">Destination</option>
            <option v-for="(dest,key) in selects.destinations" :key="key" :selected="filters.destination === dest.value" :value="dest.value">{{ dest.name }}</option>
          </select>
        </div>

        <div class="datepicker-wrapper check-in">
          <date-picker v-model="dates" :multi-calendars="true" placeholder="Check in/out"></date-picker>
        </div>

        <div class="select-wrapper guests">
          <svg class="svg svg-guests" viewBox="0 0 16 21">
            <use xlink:href="#guests"></use>
          </svg>
          <select v-model="filters.guests">
            <option :selected="filters.guests === '-1'" value="-1">Guests</option>
            <option v-for="(guest ,key) in selects.guests" :key="key" :selected="filters.guests === guest.value" :value="guest.value">{{ guest.name }}</option>
          </select>
        </div>

        <div class="select-wrapper bed">
          <svg class="svg svg-bedroom" viewBox="0 0 16 21">
            <use xlink:href="#bedroom"></use>
          </svg>
          <select v-model="filters.bedrooms">
            <option :selected="filters.bedrooms === '-1'" value="-1">Bedrooms </option>
            <option v-for="(bed ,key) in selects.bedrooms" :key="key" :selected="filters.bedrooms === bed.value" :value="bed.value">{{ bed.name }}</option>
          </select>
        </div>

        <button type="submit" class="button primary" @click.prevent="findProperties">
            <span>
              Find my Villa
            </span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import DatePicker from "./DatePicker";

export default {
  components: {
    'date-picker': DatePicker
  },
  props: {
    destination: {
      type: String,
      default: ""
    },
    destinations: {
      type: Array,
      default: ""
    },
    guests:{
      type: String,
      default: ""
    },
    bedrooms: {
      type: String,
      default: ""
    },
    checkin: {
      type: String,
      default: ""
    },
    checkout: {
      type: String,
      default: ""
    }
  },
  mounted() {
    console.log("Luna Cabo Property Search mounted!");

    if (this.destinations.length) {
      this.selects.destinations = this.destinations;
    }

    if (this.destination.length) {
      this.filters.destination = this.destination;
    } else {
      this.filters.destination = "-1";
    }

    if (this.guests.length) {
      this.filters.guests = this.guests;
    } else {
      this.filters.guests = "-1";
    }

    if (this.bedrooms.length) {
      this.filters.bedrooms = this.bedrooms;
    } else {
      this.filters.bedrooms = "-1";
    }

    if (this.checkin.length) {
      this.filters.checkin = this.checkin;
    }

    if (this.checkout.length) {
      this.filters.checkout = this.checkout;
    }

    if (this.checkin.length && this.checkout.length) {
      this.dates = [this.checkin, this.checkout];
    }

  },
  data() {
    return {
      selects: {
        destinations: [],
        guests: [
          {name: '1 Guest', value: '1'},
          {name: '2 Guests', value: '2'},
          {name: '3 Guests', value: '3'},
          {name: '4 Guests', value: '4'},
          {name: '5 Guests', value: '5'},
          {name: '6 Guests', value: '6'},
          {name: '7 Guests', value: '7'},
          {name: '8 Guests', value: '8'},
          {name: '9 Guests', value: '9'},
          {name: '10 Guests', value: '10'},
          {name: '11 Guests', value: '11'},
          {name: '12 Guests', value: '12'},
          {name: '13 Guests', value: '13'},
          {name: '14 Guests', value: '14'},
          {name: '15 Guests', value: '15'},
          {name: '16 Guests', value: '16'},
          {name: '17 Guests', value: '17'},
          {name: '18 Guests', value: '18'},
          {name: '19 Guests', value: '19'},
          {name: '20 Guests', value: '20'},
          {name: '21 Guests', value: '21'},
          {name: '22 Guests', value: '22'},
          {name: '23 Guests', value: '23'},
          {name: '24 Guests', value: '24'},
          {name: '25 Guests', value: '25'},
        ],
        bedrooms: [
          {name: '1 Bedroom', value: '1' },
          {name: '2 Bedrooms', value: '2'},
          {name: '3 Bedrooms', value: '3'},
          {name: '4 Bedrooms', value: '4'},
          {name: '5 Bedrooms', value: '5'},
          {name: '6 Bedrooms', value: '6'},
          {name: '7 Bedrooms', value: '7'},
          {name: '8 Bedrooms', value: '8'},
          {name: '9 Bedrooms', value: '9'},
          {name: '10 Bedrooms', value: '10'},
          {name: '11 Bedrooms', value: '11'},
          {name: '12 Bedrooms', value: '12'},
        ],
        checkin: [
          {name: 'option 1', value: '1'},
        ],
        checkout: [
          {name: 'option 1', value: '1'},
        ]
      },
      filters: {
        destination:'-1',
        guests:'-1',
        bedrooms: '-1',
        checkin: '-1',
        checkout: '-1'
      },
      dates: null,
      matches: [],
      showLoader: false,
    };
  },
  watch: {
    dates: {
      handler(dates) {
        if (!dates || dates.length < 1) {
          this.filters.checkin = '-1';
          this.filters.checkout = '-1';
          return;
        }

        this.filters.checkin = this.formatDate(dates[0]);
        this.filters.checkout = dates.length > 1 ? this.formatDate(dates[1]) : '-1';
      },
      deep: true
    }
  },
  methods: {
    async getDestinations() {
      const url = window.luna_cabo_Data.restApis.properties;
      this.showLoader = true;
      const response = await axios.get(url);
      this.showLoader = false;
      if (response.data.success) {
        this.selects.destinations = response.data.response;

        if (this.destination.length) {
          this.filters.destination = this.destination;
        }
        if (this.guests.length) {
          this.filters.guests = this.guests;
        }
        if (this.bedrooms.length) {
          this.filters.bedrooms = this.bedrooms;
        }
        if (this.checkin.length) {
          this.filters.checkin = this.checkin;
        }
        if (this.checkout.length) {
          this.filters.checkout = this.checkout;
        }

        if (this.checkin.length && this.checkout.length) {
          this.dates = [this.checkin, this.checkout];
        }

      }
    },
    async findProperties() {

      let url = `/villas?`;
      let params = [];

      console.log(this.filters)
      console.table(Object.entries(this.filters))

      for (const key in this.filters) {
          let value = this.filters[key];

          if (key === 'destination' && value !== '-1') {
            value = this.slugify(this.filters[key]);
          }

          if (value !== '-1') {
            params.push(`${key}=${value}`);
          }
      }

      if (params.length) {
        url += params.join("&")
      }

      // this.action = url;
      location.href = encodeURI(url);
    },
    slugify(string) {
      string = string.toLowerCase();
      string = string.replace(/[^a-z0-9- ]/g, "");
      string = string.replace(/ +/g, "-");
      return string.trim();
    },
    formatDate(date, formatter = "yyyy-MM-DDTHH:mm:ss") {
      return moment(date).format(formatter);
    }
  },
  computed: {},
}
</script>