<template>
  <div class="card-container">
    <component :is="getTag()" class="card" :class="classes" :href="card.href" :style="getStyle()">
      <div v-if="displayImage()" class="block image">
        <img :src="card.imagePath" :alt="card.imageAlt">
      </div>
      <div v-if="displayContent()" class="block content">
        <div v-if="displayCardHeader()" class="card__header">
          <card-title :titleLine1="card.titleLine1" :titleLine2="card.titleLine2"
                      :titleTag="card.titleTag"></card-title>
          <div class="rating" v-if="card.type==='property' && typeof card.rating !== 'undefined'"><span>5.0</span></div>
        </div>
        <div class="card__body" v-if="card.type==='property'">
          <div class="details">
            <span class="guests">{{ card.guests }} Guests </span>
            <span class="bedrooms">{{ card.bedrooms }} Bedrooms</span>
            <span class="bathrooms" v-if="parseInt(card.bathrooms) !== 0">{{ card.bathrooms }}  Bathrooms</span>
          </div>
          <div class="price">
            From <span class="amount">{{ card.min_price }} / Night</span>
          </div>
        </div>
        <div v-if="displayCardBody()" class="card__body">
          {{ card.body }}
        </div>
      </div>
      <div v-if="displayCardFooter()" class="card__footer">
        <a class="button card-button" :href="card.href">{{ card.ctaText }}</a>
      </div>
    </component>
  </div>

</template>

<script>
import CardTitle from "./CardTitle";

export default {
    components: {CardTitle},
    data: function () {
        return {
            defaultClass: 'align-center',
        }
    },
    props: {
        card: {
            type: Object,
            default: false
        }
    },
    mounted() {
        // console.log("Card component mounted!");
    },
    computed: {
        type: function () {
            return this.card.type ? this.card.type : 'base';
        },
        classes: function () {
            let classes
            classes = this.card.classes ? this.card.classes : this.defaultClass;
            return classes + ' ' + this.getTag();
        },
    },
    methods: {
        getTag: function () {
            return (this.card.href !== "" && this.card.href !== null) ? 'a' : 'div';
        },
        getStyle: function () {
            let style = ''
            if (this.card.imagePath && (this.type !== 'base' && this.type !== 'property')) {
                style += 'background-image: url(' + this.card.imagePath + ');';
            }
            if (this.card.overlayColor) {
                style += 'background-color:' + this.card.overlayColor + ';';
            }
            return style;
        },
        displayImage: function () {
            return (this.card.imagePath && (this.type === 'base' || this.type === 'property'));
        },
        displayContent: function () {
            return this.card.titleLine1 || this.card.titleLine2 || this.card.body || (this.card.ctaText && this.card.href);
        },
        displayCardHeader: function () {
            return this.card.titleLine1 || this.card.titleLine2;
        },
        displayCardBody: function () {
            return this.card.body;
        },
        displayCardFooter: function () {
            return this.card.ctaText && this.card.href;
        }
    }
}
</script>
