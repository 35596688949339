<template>
  <Datepicker class="date-picker"
              ref="datepicker"
              v-model="date"
              :name="name"
              :placeholder="placeholder"
              :range="isRange"
              :multi-calendars="multiCalendars"
              :auto-apply="autoApply"
              :enable-time-picker="enableTimePicker"
              @internalModelChange="onModelChange"
              @cleared="onCleared"
              @open="onOpen"
              @closed="onClose">
    <slot></slot>
  </Datepicker>
</template>

<script>
// Datepicker lib full documentation: https://vue3datepicker.com/
import Datepicker from '@vuepic/vue-datepicker';

export default {
  components: { Datepicker },

  props: {
    modelValue: {
      type: [Date, String, Array, Object]
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isRange: {
      type: Boolean,
      default: true
    },
    multiCalendars: {
      type: Boolean,
      default: false
    },
    autoApply: {
      type: Boolean,
      default: false
    },
    enableTimePicker: {
      type: Boolean,
      default: false
    },
    autoCloseRangeOnSelected: {
      type: Boolean,
      default: true
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      date: this.modelValue ? this.modelValue : null,
      isOpen: false,
      modelChanged: false,
    };
  },

  watch: {
      modelValue: {
          handler(dates) {
              if (!this.modelChanged && dates && dates.length > 0) {
                  this.date = dates;
              }
          },
          deep: true
      }
  },

  methods: {
    close() {
      this.$refs.datepicker.closeMenu();
    },
    selectCurrent() {
      this.$refs.datepicker.selectDate();
    },
    onOpen() {
      this.isOpen = true;
      this.$emit('datepicker:opened');
    },
    onClose() {
      this.isOpen = false;
      this.$emit('datepicker:closed');
    },
    onCleared() {
        this.$emit('update:modelValue', null);
    },
    onModelChange(date) {
      if (!this.isOpen) {
        return;
      }

      this.modelChanged = true;

      this.$emit('update:modelValue', date);

      if (!this.autoCloseRangeOnSelected) {
        return;
      }

      if (this.isRange && Array.isArray(date) && date.length === 2) {
        this.selectCurrent();
        this.$nextTick(() => {
          this.close();
        });
      }
    }
  }
}
</script>
